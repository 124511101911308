<template>
  <!-- 行驶数据 -->
  <div ref="container" class="chart" :class="{
    [$style.chart]: true,
    [$style.large]: large
  }"></div>
</template>

<style module lang="scss">
.chart {
  height: 8rem; 
  margin-top: -1.7rem;
}
.large {
  height: 17rem;
}
</style>

<script>
import DataSet from "@antv/data-set";
import { Chart } from "@antv/g2";

const { remUnit = 20 } = window;

export default {
  props: {
    title: {
      type: String,
    },
    large: {
      type: Boolean,
      default: false
    },
    vData: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      chart: undefined
    };
  },
  mounted() {
    const { vData } = this;
    
    const data = this.transformData(vData);

    this.initChart(data);
  },
  watch: {
    vData(val) {
      const data = this.transformData(val);
      this.initChart(data);
    }
  },
  methods: {
    transformData(data) {
      const dv = new DataSet.DataView().source(data);

      dv.transform({
        type: "rename",
        map: {
          running: "运行中",
          stopped: "未运行",
          breakRule: "违规",
        },
      }).transform({
        type: "fold",
        fields: ["运行中", "未运行", "违规"], // 展开字段集
        key: "type", // key字段
        value: "value", // value字段
      });

      return dv.rows;
    },
    initChart(data) {
      const { large } = this;
      const { container } = this.$refs;
      let { chart } = this;

      if (!data || data.length === 0) return;

      if (chart) {
        chart.changeData(data);

        return;
      }
      
      chart = new Chart({
        container,
        autoFit: true,
        padding: [(large ? 4 : 2.5) * remUnit, 1 * remUnit, 1.5 * remUnit, 2 * remUnit],
      });

      chart.scale({
        value: {
          nice: true,
        },
        date: {
          range: [0, 1],
        },
      });
      chart.tooltip({
        // showCrosshairs: true,
        shared: true,
      });
      chart.axis('date', {
        grid: {
          line: {
            style: {
              stroke: '#343B74',
            },
          },
        },
        line: {
          style: {
            stroke: '#343B74',
          },
        },
        tickLine: null,
        label: {
          style: {
            fontSize: (large ? 0.7 : 0.6) * window.remUnit,
            fill: '#5961A6',
          },
          formatter: (val) => {
            return val.replace(/\d{4}-/, '');
          },
        },
      });
      chart.axis('value', {
        grid: null,
        label: {
          style: {
            fontSize: (large ? 0.7 : 0.6) * window.remUnit,
            fill: '#5961A6',
          },
        }
      });
      // 图例
      chart.legend({
        position: 'top',
        marker: {
          symbol: 'circle',
          spacing: 0,
        },
        offsetX: 1 * window.remUnit,
        flipPage: false,
        itemSpacing: (large ? 1 : 0.2) * window.remUnit,
        itemName: {
          style: {
            fontSize: (large ? 0.7 : 0.6) * window.remUnit,
            fill: '#888FCD',
          },
          formatter(text) {
            return text;
          },
        },
      });

      chart.line().position("date*value").color("type").shape('smooth');
      chart.data(data);
      chart.render();
      this.chart = chart;
    },
  },
};
</script>